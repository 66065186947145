import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import { FaBars, FaTimes } from "react-icons/fa";

const TopBar = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  const handleCategoryClick = (categoryId) => {
    navigate(`/product/${categoryId}`);
    setIsOpen(false);
  };

  const handleCategoriesClick = () => {
    navigate("/product");
    setIsOpen(false);
  };

  return (
    <div className="w-full bg-black text-white py-3 px-4 flex justify-between items-center font-semibold">
      {/* Contenedor para mantener el contenido centrado */}
      <div className="w-full flex justify-between items-center max-w-screen-xl mx-auto">
        {/* Botón de hamburguesa en móviles */}
        <button onClick={() => setIsOpen(!isOpen)} className="md:hidden text-white text-2xl">
          {isOpen ? <FaTimes /> : <FaBars />}
        </button>

        {/* Menú normal en pantallas grandes */}
        <div className="hidden md:flex space-x-6 flex-grow justify-center">
          <div className="relative cursor-pointer" onClick={handleCategoriesClick}>
            Categorías
            <div className="absolute left-0 mt-2 w-40 bg-white text-black shadow-lg rounded-md hidden group-hover:block">
              <a className="block px-4 py-2 hover:bg-gray-200" onClick={(e) => { e.stopPropagation(); handleCategoryClick("papeleria"); }}>Papelería</a>
              <a className="block px-4 py-2 hover:bg-gray-200" onClick={(e) => { e.stopPropagation(); handleCategoryClick("tecnologia"); }}>Tecnología</a>
              <a className="block px-4 py-2 hover:bg-gray-200" onClick={(e) => { e.stopPropagation(); handleCategoryClick("moda"); }}>Moda</a>
              <a className="block px-4 py-2 hover:bg-gray-200" onClick={(e) => { e.stopPropagation(); handleCategoryClick("hogar"); }}>Hogar</a>
            </div>
          </div>
          <a href="#" className="hover:underline">Ofertas</a>
          <a href="#" className="hover:underline">Novedades</a>
          <a href="#" className="hover:underline">Populares</a>
          <a href="#" className="hover:underline">Recomendados</a>
        </div>
      </div>

      {/* Menú deslizante en móviles */}
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ x: "-100%" }}
            animate={{ x: 0 }}
            exit={{ x: "-100%" }}
            transition={{ duration: 0.3 }}
            className="fixed top-0 left-0 w-3/4 h-full bg-black text-white shadow-lg z-50 p-6 flex flex-col space-y-4"
          >
            <button onClick={() => setIsOpen(false)} className="self-end text-2xl">
              <FaTimes />
            </button>
            <div className="cursor-pointer" onClick={handleCategoriesClick}>Categorías</div>
            <a href="#" className="block hover:bg-gray-200 px-4 py-2">Ofertas</a>
            <a href="#" className="block hover:bg-gray-200 px-4 py-2">Novedades</a>
            <a href="#" className="block hover:bg-gray-200 px-4 py-2">Populares</a>
            <a href="#" className="block hover:bg-gray-200 px-4 py-2">Recomendados</a>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default TopBar;
