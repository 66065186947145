import React, { useState } from "react";
import { NavLink, Link } from "react-router-dom";
import logo from "../../assets/images/logoMC-w.png";
import openMenu from "../../assets/images/open-menu.svg";
import closeMenuIcon from "../../assets/images/close-menu.svg";
import SuccessMsg from "../../components/SuccessMsg";

const Header = ({
  loginModal,
  handleLogout,
  validLogin,
  activateLoginModal,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);
  const closeMenu = () => setIsMenuOpen(false);

  return (
    <header className="absolute top-0 left-0 z-10 w-full bg-transparent">
      {loginModal}
      <nav className="flex items-center justify-between h-[90px] px-6 md:px-10">
        {/* Logo */}
        <NavLink onClick={closeMenu} to="/" className="w-auto">
          <img className="w-[190px] md:w-[250px] h-auto" src={logo} alt="MarketConnect Logo" />
        </NavLink>

        {/* Botón de menú en móviles */}
        <button
          className="md:hidden z-50 p-2"
          onClick={toggleMenu}
          aria-label="Toggle menu"
        >
          <img src={isMenuOpen ? closeMenuIcon : openMenu} alt="Menu Icon" className="w-6 h-6" />
        </button>

        {/* Menu Container */}
        <div
          className={`fixed top-0 right-0 w-[75%] h-full bg-black/80 backdrop-blur-md transition-transform transform ${
            isMenuOpen ? "translate-x-0" : "translate-x-full"
          } md:relative md:w-auto md:h-auto md:bg-transparent md:translate-x-0`}
          aria-hidden={!isMenuOpen}
          tabIndex={isMenuOpen ? 0 : -1}
        >
          <ul className="flex flex-col md:flex-row gap-4 md:gap-6 items-start md:items-center p-6 md:p-0 font-semibold">
            {[
              { path: "/", label: "Inicio" },
              { path: "/servicios", label: "Servicios" },
              { path: "/marketplace", label: "MarketPlace" },
              { path: "/acercade", label: "Acerca de" },
            ].map(({ path, label }) => (
              <li key={path} className="w-full md:w-auto">
                <NavLink
                  onClick={closeMenu}
                  to={path}
                  className="block text-white text-lg md:text-sm py-2 md:py-0"
                >
                  {label}
                </NavLink>
              </li>
            ))}

            {validLogin && (
              <li>
                <NavLink
                  onClick={closeMenu}
                  to="/usuarios-menu-admin"
                  className="text-white text-lg md:text-sm"
                >
                  Usuarios
                </NavLink>
              </li>
            )}

            <li>
              {validLogin ? (
                <Link
                  to="/"
                  className="px-5 py-2 rounded-md bg-red-600 hover:bg-red-700 transition text-white text-sm md:text-base font-semibold"
                  onClick={() => {
                    closeMenu();
                    handleLogout();
                  }}
                >
                  Cerrar sesión
                </Link>
              ) : (
                <button
                  className="px-5 py-2 rounded-md bg-blue-600 hover:bg-blue-700 transition text-white text-sm md:text-base font-semibold"
                  onClick={() => {
                    closeMenu();
                    activateLoginModal();
                  }}
                >
                  Iniciar sesión
                </button>
              )}
            </li>
          </ul>
        </div>
      </nav>
      <SuccessMsg />
    </header>
  );
};

export default Header;
