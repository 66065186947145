import React from "react";
import FooterMenu from "./FooterMenu";
import FooterContact from "./FooterContact";
import FooterLegal from "./FooterLegal";
import iconoF from '../../assets/images/socials/facebook.png'
import iconoX from '../../assets/images/socials/twitterW.png'
import iconoI from '../../assets/images/socials/instagram.png'
import iconoT from '../../assets/images/socials/tiktok.png'
import logoW from '../../assets/images/socials/logoMC-w.png'

export default class Footer extends React.Component {
  render() {
    return (
      <footer className="relative bg-black pt-8">
        <div className="container mx-auto px-4 text-white">
          <div className="flex flex-wrap text-left">
            <div className="w-full lg:w-6/12 px-4">
              <a href="#" className="block mb-6">
                <img src={logoW} alt="MarketConnect Logo" className="mx-auto sm:mx-0" />
              </a>
              <div className="mt-6 lg:mb-0 ml-2 mb-6 wrapper text-center">
                <p className='text-xl font-bold ml-2 pb-2 md:text-left text-center'>Redes Sociales</p>
                <div className="flex space-x-4 ml-2 pt-2 mb-5 justify-center md:justify-start">
                  <a href="https://www.facebook.com/MarketConnectMexico?mibextid=ZbWKwL" target="_blank" className='bg-white rounded-full p-2 shadow-lg shadow-[#14b8a6]'>
                    <img src={iconoF} alt="Facebook" className="w-6" />
                  </a>
                  <a href="#" target="_blank" className='bg-white rounded-full p-2 shadow-lg shadow-[#14b8a6]'>
                    <img src={iconoX} alt="Twitter" className="w-6" />
                  </a>
                  <a href="https://instagram.com/market.connect_?igshid=MzRlODBiNWFlZA=" target="_blank" className='bg-white rounded-full p-2 shadow-lg shadow-[#14b8a6]'>
                    <img src={iconoI} alt="Instagram" className="w-6" />
                  </a>
                  <a href="https://www.tiktok.com/@market_connect?_t=8gFLiPflJ14&_r=1" target="_blank" className='bg-white rounded-full p-2 shadow-lg shadow-[#14b8a6]'>
                    <img src={iconoT} alt="TikTok" className="w-6" />
                  </a>
                </div>
              </div>
            </div>
            <div className="w-full lg:w-6/12 px-4 grid grid-cols-1 sm:grid-cols-2 gap-4 md:pt-12">
              <div className="flex flex-col">
                <ul className="list-unstyled">
                  <li>
                    <a className="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm" href="#">Inicio</a>
                  </li>
                  <li>
                    <a className="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm" href="/servicios">Servicios</a>
                  </li>
                  <li>
                    <a className="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm" href="/Marketplace">Productos</a>
                  </li>
                  <li>
                    <a className="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm" href="#">Importaciones</a>
                  </li>
                  <li>
                    <a className="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm" href="/acercade">Acerca de</a>
                  </li>
                </ul>
              </div>
              <div className="wrapper pl-8">
                <div className="mb-4">
                  <p className='text-xl font-bold'>Contacto</p>
                  <p className='text-sm'>Teléfono: 5611678552</p>
                  <p className='text-sm'>Dirección: Calle Santa Cruz 9, San Miguel Totolcingo, Acolman, Estado de México, C.P: 55885</p>
                  <p className='text-sm'>Email: gsanchez@promarketconnect.com</p>
                </div>
              </div>
            </div>
          </div>
          <hr className="my-5 border-gray-400" />
          <div className="flex flex-wrap md:justify-between justify-center">
            <div className="w-full px-4 mx-auto">
              <div className="text-center mb-5">
                <p className='text-sm text-gray-300'>&copy; Copyright 2024 MarketConnect. Todos los derechos reservados.</p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}
