import React from "react";
import image1 from "../../assets/images/catalogo/0X4A2118.jpg";
import image2 from "../../assets/images/catalogo/0X4A2119.jpg";
import image3 from "../../assets/images/catalogo/0X4A2130.jpg";
import image4 from "../../assets/images/catalogo/0X4A2149.jpg";
import image5 from "../../assets/images/catalogo/0X4A2109.jpg";
import image6 from "../../assets/images/catalogo/0X4A2108.jpg";
import image7 from "../../assets/images/catalogo/boardmarket.jpeg";
import image8 from "../../assets/images/catalogo/lapiz3cajas.png";
import image9 from "../../assets/images/catalogo/0X4A2185.jpg";
import image10 from "../../assets/images/catalogo/0X4A2186.jpg";
import image11 from "../../assets/images/catalogo/dino.jpeg";
import image12 from "../../assets/images/catalogo/biciroja.jpeg";

const ImageCardSection = () => {
  const cards = [
    { id: 1, title: "Hogar", largeImage: image1, smallImages: [image2, image3, image4] },
    { id: 2, title: "Escolar", largeImage: image5, smallImages: [image6, image7, image8] },
    { id: 3, title: "Para los niños", largeImage: image9, smallImages: [image10, image11, image12] },
  ];

  return (
    <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 px-4 py-6">
      {cards.map((card) => (
        <div
          key={card.id}
          className="rounded-lg bg-gray-900 shadow-md hover:shadow-lg transition-shadow duration-200 ease-in-out"
        >
          <h3 className="text-center text-lg font-semibold text-white py-3">{card.title}</h3>
          <img
            src={card.largeImage}
            alt={`Imagen grande ${card.id}`}
            className="mb-4 w-full h-48 object-cover rounded-lg"
          />
          <div className="grid grid-cols-3 gap-2">
            {card.smallImages.map((img, index) => (
              <img
                key={index}
                src={img}
                alt={`Imagen pequeña ${card.id}-${index + 1}`}
                className="h-16 w-full object-cover rounded-lg"
              />
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default ImageCardSection;
