import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCreditCard, faShieldAlt, faHeadset } from "@fortawesome/free-solid-svg-icons";

const FinalBanner = () => {
  const banners = [
    {
      id: 1,
      icon: faCreditCard,
      text: "Elige cómo pagar",
      subtext: "Paga con tarjeta, débito o efectivo."
    },
    {
      id: 2,
      icon: faShieldAlt,
      text: "Seguridad, de principio a fin",
      subtext: "¿No te gusta? ¡Devuélvelo!"
    },
    {
      id: 3,
      icon: faHeadset,
      text: "Atención al cliente",
      subtext: "Estamos aquí para ayudarte 24/7 con cualquier pregunta."
    }
  ];

  return (
    <div className="w-full bg-black py-8 px-4">
      <div className="max-w-7xl mx-auto grid grid-cols-1 md:grid-cols-3 gap-6">
        {banners.map((banner) => (
          <div
            key={banner.id}
            className="flex flex-col items-center text-center p-4 bg-black rounded-2xl shadow-md transition-all hover:shadow-lg"
          >
            <FontAwesomeIcon
              icon={banner.icon}
              size="3x"
              className="text-white mb-3"
              aria-label={banner.text}
            />
            <h3 className="text-lg font-semibold">{banner.text}</h3>
            <p className="text-sm text-white">{banner.subtext}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FinalBanner;
